import React from 'react';
import classNames from 'classnames';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'; // Import TikTok icon

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://facebook.com/">
            <FaFacebookF size={16} /> {/* Facebook Icon */}
          </a>
        </li>
        <li>
          <a href="https://tiktok.com/">
            <FaTiktok size={16} /> {/* TikTok Icon */}
          </a>
        </li>
        <li>
          <a href="https://instagram.com/">
            <FaInstagram size={16} /> {/* Instagram Icon */}
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;
