import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [message, setMessage] = useState(''); // הגדרת useState ל-message

  // פונקציה לשליחת הודעה לווטסאפ
  const sendMessage = () => {
    const phoneNumber = '972504404144';  // החלף במספר הוואטסאפ שלך כולל קוד המדינה

const modifiedMessage = "שלום, אני פונה אליך דרך האתר בנוגע ל: " + message;
  const encodedMessage = encodeURIComponent(modifiedMessage); // קידוד ההודעה
      const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // פותח את הוואטסאפ עם ההודעה המוכנה
    window.open(url, '_blank');
  };

  // יצירת מחלקות CSS לדיבים
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              אם אתה שואף לגדול, זה הזמן להוכיח את עצמך! הצטרף לאימון ניסיון עכשיו.
            </h3>
          </div>

          <div className="cta-action">
            <Input
              id="newsletter"
              type="text"
              label="כתוב לי ווטסאפ"
              labelHidden
              hasIcon="right"
              placeholder="כתוב את ההודעה שלך כאן"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            >
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>

            <button onClick={sendMessage}>
              שלח לנו בוואטסאפ
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
