import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import { useHistory } from 'react-router-dom'; // useHistory for React Router v5

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Main = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [isMuted, setIsMuted] = useState(true); // State to manage video sound

  const history = useHistory(); // useHistory hook for React Router v5



  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const outerClasses = classNames(
    'main section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'main-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="main-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">EZ Academy</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                אקדמיה מקצועית לכדורגל
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="https://ezacademy.co.il/">
                    סרטונים
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://app.ezacademy.co.il/login">
                    כניסה למערכת
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="main-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            {/* Video Wrapper */}
            <div className="video-wrapper">
              <video
                className="has-shadow"
                src={require('./../../assets/videos/main_video.mp4')}
                autoPlay
                muted={isMuted}
                loop
                controls={false}
                playsInline
                width={504}
                height={504}
              />
              <button className="sound-toggle" onClick={toggleMute}>
                {isMuted ? '🔇 ' : '🔊 '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
