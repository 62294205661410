import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = () => {
  return (
    <div className="logo-container">
      <Link to="/">
        <Image
          src={require('./../../../assets/images/main_logo.png')}
          alt="EZ Academy Logo"
          width={120}
          height={140}
        />
      </Link>
    </div>
  );
};

export default Logo;
