import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

const sectionHeader = {
  title: 'הדרך שלך להצלחה',
  paragraph: [
    'אימונים מקצועיים לכל צורך ומטרה',
    'באקדמיית EZ Academy אנו מציעים מגוון רחב של אימונים ושירותים שמותאמים אישית לכל שחקן, על מנת לקדם אותו בצורה מקסימלית. האימונים שלנו לא רק ממוקדים בטכניקה ויכולת משחק, אלא כוללים גם הכוונה מקצועית בתחומים נוספים כגון כושר גופני, תזונה ומנטליות – כל מה ששחקן צעיר צריך כדי להיות מוכשר ומוכן למגרש.',
    'בין אם מדובר באימוני טכניקה אישיים, תכנית תזונה מותאמת, אימוני כושר ממוקדים או ליווי מנטלי – אנחנו כאן בשבילך. כל אחד יכול למצוא את החבילה שמתאימה לצרכים האישיים שלו, ולהתמקד בשיפור של כל היבט שיכול לעזור לו להגיע לרמה הגבוהה ביותר.'
  ]
};

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u">
                  בעלים
                </div>
                <h3 className="mt-0 mb-12">
                  עדן וינשטיין
                </h3>
                <p className="m-0">

                  עדן וינשטיין הוא מאמן עם תשוקה עזה לכדורגל ולפיתוח שחקנים צעירים. כמי שהיה שחקן נבחרת ישראל בגילאי נערים ונוער, הוא מכיר את הדרך המאתגרת להגיע לרמות הגבוהות ביותר, ומעביר את הידע והניסיון שלו לשחקנים הצעירים באקדמיה. עדן ידוע בגישה האישית והחמה שהוא מעניק לכל ילד, תוך שימת דגש על שיפור טכני, מנטליות בריאה ותנאים גופניים מיטביים. הוא מאמין בכוחם של הילדים להצליח ונותן להם את הכלים וההכוונה הנכונה להוציא את המיטב מעצמם.

במהלך הקריירה שלו, עדן צבר ידע מקצועי רב שנבנה על שנים של עבודה עם ילדים ונוער. הוא מאמין שכל ילד יכול להגיע להישגים מרשימים, והמשימה שלו היא לספק להם את הסביבה הנכונה, את התמיכה וההכוונה כדי להצליח על המגרש ומחוצה לו.

                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/coaches/eden.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  בעלים
                </div>
                <h3 className="mt-0 mb-12">
                  זך יוסף
                </h3>
                <p className="m-0">

                  זך יוסף הוא מאמן עם ניסיון עשיר במשחק ובאימון, שהיה שחקן נבחרת ישראל בגילאי נערים ונוער. לאחר שנים של חוויות מקצועיות ככדורגלן, זך פנה לאימון ומצא את הייעוד שלו בהדרכת דור העתיד של הכדורגל. הגישה של זך מאופיינת באמונה בשחקנים הצעירים וביכולת שלהם להוציא מעצמם את המיטב, גם כאשר מדובר באתגרים מקצועיים או אישיים.

זך מחויב להקנות לילדים את הכלים להצליח – לא רק מבחינה טכנית, אלא גם מבחינה מנטלית, עם דגש על חיזוק הביטחון העצמי והתמודדות עם קשיים. הוא רואה את התפקיד שלו לא רק כמדריך מקצועי, אלא גם כמודל לחיקוי, שמוביל את השחקנים בדרך בה יוכלו להמשיך ולהתפתח בעתיד, על המגרש ובחיים בכלל.

                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/coaches/zach.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            {/*<div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Eficácia garantida com inteligência artificial 
                </div>
                <h3 className="mt-0 mb-12">
                  Aumente o tempo de atividade com monitoramento 24/7 
                </h3>
                <p className="m-0">
                  Use IA para detectar e parar rapidamente os problemas, alertando o recurso certo para inspecionar, diagnosticar e retificar qualquer problema, em qualquer lugar.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/image3.webp')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>*/}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;