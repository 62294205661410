import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom"; // Use Router instead of BrowserRouter
import { createBrowserHistory } from "history"; // Import createBrowserHistory

import App from './App';
import * as serviceWorker from './serviceWorker';

// import './App.css';
import './assets/scss/style.scss';

const history = createBrowserHistory(); // Create history object

ReactDOM.render(
  <div style={{ direction: 'rtl' }}> {/* Set the direction here */}
    <Router history={history}> {/* Use Router and pass the history */}
      <App />
    </Router>
  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
