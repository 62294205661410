import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

const sectionHeader = {
  title: 'היתרון שלך על המגרש מתחיל כאן.',
  paragraph: `אקדמיית EZ Academy – הדרך שלך להצלחה בכדורגל!
בין אם אתה שחקן צעיר שרק מתחיל את הדרך או שחקן מנוסה שמחפש להעלות את המשחק שלו לשלב הבא, אנחנו כאן כדי להוביל אותך להישגים.
עם מאמנים מהטובים ביותר בארץ, תכניות אימון מותאמות אישית, ותמיכה מקצועית בכל התחומים שקשורים לפיתוח שחקן, אקדמיית EZ Academy מציעה את הסביבה המושלמת להגשמת החלום.
כאן תלמד את כל מה שצריך לדעת על שליטה בכדור, הבנת המשחק, כושר גופני, תזונה ומנטליות שמנצחת.
הצטרף אלינו ותהפוך לשחקן טוב יותר, חכם יותר ומוכן לכל אתגר על המגרש.`
};

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/orian.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    אוריאן גורן
                    </h4>
                  <p className="m-0 text-sm">
                    ברצלונה 2009
                  </p>
                </div>
              </div>
            </div>

          </div>



          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/avatar.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    ליאם ויינשטיין
                    </h4>
                  <p className="m-0 text-sm">
                    מכבי פתח תקווה 2009
                  </p>
                </div>
              </div>
            </div>

          </div>


          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/avatar.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    דולב חזיזה
                    </h4>
                  <p className="m-0 text-sm">
                    מכבי חיפה 1995
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/avatar.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    ביבי וינשטיין
                  </h4>
                  <p className="m-0 text-sm">
                    הפועל תל אביב 2025
                  </p>
                </div>
              </div>
            </div>


          </div>




          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/avatar.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    עומר עוז
                    </h4>
                  <p className="m-0 text-sm">
                    מכבי עמישב 2013
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/players/avatar.jpg')}
                      alt="Features tile icon 02"
                      width={150}
                      height={150} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    נועם עוז
                  </h4>
                  <p className="m-0 text-sm">
                    מכבי פתח תקווה 2016
                  </p>
                </div>
              </div>
            </div>


          </div>

          </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;