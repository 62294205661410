import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const propTypes = {
  tag: PropTypes.elementType,
  color: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  wideMobile: PropTypes.bool,
  disabled: PropTypes.bool
}

const defaultProps = {
  tag: 'button',
  color: '',
  size: '',
  loading: false,
  wide: false,
  wideMobile: false,
  disabled: false
}

const Button = ({
  className,
  tag,
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  style, // קבלת style מותאם אישית
  ...props
}) => {

  const classes = classNames(
    'button',
    color && `button-${color}`,
    size && `button-${size}`,
    loading && 'is-loading',
    wide && 'button-block',
    wideMobile && 'button-wide-mobile',
    className
  );

  const Component = tag;

  return (
    <Component
      {...props}
      className={classes}
      disabled={disabled}
      style={{
        backgroundColor: color || '#8BC53F', // ברירת מחדל לצבע
        color: 'white', // צבע טקסט ברירת מחדל
        borderRadius: '50px', // פינות עגולות כברירת מחדל
        padding: '12px 24px', // רווח פנימי ברירת מחדל
        border: 'none', // בלי מסגרת
        cursor: disabled ? 'not-allowed' : 'pointer', // סמן תלוי מצב
        opacity: disabled ? 0.6 : 1, // שקיפות אם הכפתור מבוטל
        transition: 'background-color 0.3s, transform 0.2s', // אנימציה
        ...style, // מתיר התאמה אישית
      }}
    />
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;